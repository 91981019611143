import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { DLT_DATA_TYPES } from '@el-data-list-table';
import { TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';

import {
  INTEGRATION_PERMISSION_ACTIONS,
  MODULES,
  PERMISSION_ACTIONS,
  REFERENCE_PERMISSION_ACTIONS,
  USER_TYPES,
} from '@shared/constants';
import {
  IModuleResponse,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { generatePermissionKey, getModulePermission } from '@shared/utils';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../../modules/auth/services';
import { validateReferenceModulePermissions } from '../../../modules/core/helpers/permission-validator.helper';
import { ModulesService } from '../../../modules/modules/services/modules.service';
import { ReferenceCategoryService } from '../../../modules/references/services/reference-category.service';
import { ColorSchemeService } from '../../../modules/setup/appearance/services/color-scheme.service';
import {
  ClientDataService,
  ClientSetupResponseDTO,
} from '../../../modules/setup/general/services/client-data.service';
import {
  IAM_STATUS,
  IamConfigService,
} from '../../../modules/setup/iam-config/services/iam-config.service';
import { NotificationsService } from '../../../modules/setup/notifications/services/notifications.service';
import {
  DMS_STATUS,
  StorageSetupService,
} from '../../../modules/setup/storage/services/storage-setup.service';
import { NotifyConfigChangeOverlayComponent } from '../../../modules/storage/components/notify-config-change-overlay/notify-config-change-overlay.component';
import { PermissionFiltrationService } from '../../../services/permission-filtration.service';

import { MenuItem, SideMenuService } from './services/side-menu.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() iconOnlyMode = true;
  @Input() darkMode: boolean;
  @Output() onClickedMenuItem: EventEmitter<boolean> = new EventEmitter();
  @Output() isItemSelected: EventEmitter<boolean> = new EventEmitter();

  menuItems: MenuItem[] = [];
  private referenceCategories: IReferenceCategoryResponse[] = [];
  private fetchedReferenceList = false;
  modulesInformation: IModuleResponse[] = [];
  loggedUserInfo: UserInfoResponseDTO;
  private subscriptions = new Subscription();

  shortcuts: ShortcutInput[] = [];

  searchValue = '';
  btnVisible = false;
  isInternalIAM = true;
  isInternalStorage = true;
  referencesFetched = false;
  moduleVisibilities: { [key: string]: boolean };
  clientInformation: ClientSetupResponseDTO | undefined;

  activeMatchOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'subset',
    fragment: 'exact',
  };

  searchText = new UntypedFormControl(null);

  iamOverlayDialogRef: MatDialogRef<NotifyConfigChangeOverlayComponent>;
  dmsOverlayDialogRef: MatDialogRef<NotifyConfigChangeOverlayComponent>;

  constructor(
    private clientDataService: ClientDataService,
    private notificationsService: NotificationsService,
    private referenceCategoryService: ReferenceCategoryService,
    private loggedUserService: LoggedUserService,
    private translate: TranslateService,
    private modulesService: ModulesService,
    private router: Router,
    private iamConfigService: IamConfigService,
    private sideMenuService: SideMenuService,
    private permissionFiltrationService: PermissionFiltrationService,
    public colorSchemeService: ColorSchemeService,
    private storageConfigService: StorageSetupService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.listenToStorageConfig();
    this.listenToIAMConfig();
    this.initializeClientData();
    this.initializeReferences();
    this.initializeModulesData();
    this.initializeLoggedUserData();
    this.initializeIamConfig();
    this.initializeConnectedStorageConfig();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push({
      key: ['alt + m'],
      label: 'Focus Side Menu',
      description: 'Alt + M',
      command: () => document.getElementById('sideMenuFilter').focus(),
      preventDefault: true,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenToStorageConfig(): void {
    const dmsConfigStatusSub = this.notificationsService
      .onStorageConfigChange()
      .subscribe({
        next: (storageConfigChanged) => {
          if (storageConfigChanged && !this.dmsOverlayDialogRef) {
            this.iamOverlayDialogRef?.close();
            this.iamOverlayDialogRef = null;
            this.dmsOverlayDialogRef = this.dialog.open(
              NotifyConfigChangeOverlayComponent,
              {
                width: '100vw',
                height: '100vh',
                maxWidth: '100vw',
                maxHeight: '100vh',
                hasBackdrop: false,
                panelClass: 'offline-dialog',
                disableClose: true,
                data: this.translate.instant(
                  'pages.dashboard.system-configuration-change.dms'
                ),
              }
            );
          }
        },
      });

    this.subscriptions.add(dmsConfigStatusSub);
  }

  private listenToIAMConfig(): void {
    const iamConfigStatusSub = this.notificationsService
      .onIamConfigChange()
      .subscribe({
        next: (iamConfigChanged) => {
          if (iamConfigChanged && !this.iamOverlayDialogRef) {
            this.dmsOverlayDialogRef?.close();
            this.dmsOverlayDialogRef = null;
            this.iamOverlayDialogRef = this.dialog.open(
              NotifyConfigChangeOverlayComponent,
              {
                width: '100vw',
                height: '100vh',
                maxWidth: '100vw',
                maxHeight: '100vh',
                hasBackdrop: false,
                panelClass: 'offline-dialog',
                disableClose: true,
                data: this.translate.instant(
                  'pages.dashboard.system-configuration-change.iam'
                ),
              }
            );
          }
        },
      });

    this.subscriptions.add(iamConfigStatusSub);
  }

  private initializeIamConfig(): void {
    this.subscriptions.add(
      this.iamConfigService.dataStore.subscribe((data) => {
        const iamStatus = data?.iam?.status;
        if (
          iamStatus === IAM_STATUS.INTERNAL ||
          iamStatus === IAM_STATUS.INTERNAL_DISCONNECTED ||
          iamStatus === IAM_STATUS.INTERNAL_PENDING
        ) {
          this.isInternalIAM = true;
        } else {
          this.isInternalIAM = false;
        }
        this.setMenuItems();
      })
    );
  }

  private initializeConnectedStorageConfig(): void {
    this.subscriptions.add(
      this.storageConfigService.dataStore.subscribe((data) => {
        const storageStatus = data?.storage?.status;
        if (
          storageStatus === DMS_STATUS.INTERNAL ||
          storageStatus === DMS_STATUS.INTERNAL_DISCONNECTED ||
          storageStatus === DMS_STATUS.INTERNAL_PENDING ||
          storageStatus === DMS_STATUS.EXTERNAL_PENDING
        ) {
          this.isInternalStorage = true;
        } else {
          this.isInternalStorage = false;
        }
        this.setMenuItems();
      })
    );
  }

  private initializeLoggedUserData(): void {
    this.subscriptions.add(
      this.loggedUserService.dataStore.subscribe((data) => {
        this.loggedUserInfo = data;
        this.setMenuItems();
      })
    );
  }

  private initializeClientData(): void {
    this.subscriptions.add(
      this.clientDataService.dataStore.subscribe((data) => {
        this.clientInformation = data;
      })
    );
  }

  private initializeReferences(): void {
    this.subscriptions.add(
      this.referenceCategoryService.allCategories.subscribe((categories) => {
        this.referenceCategories = categories;
        const referenceMenuItemIndex = this.menuItems.findIndex(
          (menuItem) => menuItem.key === MODULES.REFERENCES
        );

        if (referenceMenuItemIndex > -1) {
          const referencesSubMenu = this.getReferencesSubMenu();

          this.menuItems[referenceMenuItemIndex].expanded =
            referencesSubMenu.some((child) => child.link === this.router.url);
          this.menuItems[referenceMenuItemIndex].children = referencesSubMenu;
        }
      })
    );
  }

  filterMenu = (item: MenuItem): boolean => {
    if (!item.allowedPermissions) return true;

    if (item.type === 'group') {
      if (
        this.permissionFiltrationService.validatePermissions({
          permissions: item.allowedPermissions,
        })
      ) {
        const visibleChildren = item.children.filter(this.filterMenu);
        if (item.key === MODULES.INTEGRATIONS) {
          const moduleDoc = this.modulesInformation.find(
            (module) => module.key === item.key
          );
          if (moduleDoc?.is_hidden) {
            return false;
          } else {
            return visibleChildren.length > 0;
          }
        } else {
          return visibleChildren.length > 0;
        }
      } else {
        return false;
      }
    } else if (item.type === 'link') {
      // show setup module only for EL Admin
      const keys = item.key.split('_');
      if (keys[0] === 'SETUP') {
        return this.loggedUserInfo?.user_type === USER_TYPES.EL_ADMIN;
      }

      // hide hidden modules
      const moduleDoc = this.modulesInformation.find(
        (module) => module.key === item.key
      );
      if (moduleDoc?.is_hidden) {
        return false;
      }

      //  check if the logged in user have enough permissions to see the module
      // Always expecting ACTION:MODULE format in permissions
      if (this.loggedUserInfo?.user_type === USER_TYPES.EL_ADMIN) {
        return true;
      }

      if (
        this.permissionFiltrationService.validatePermissions({
          permissions: item.allowedPermissions,
        })
      ) {
        return true;
      }

      return false;
    }

    return true;
  };

  private initializeModulesData(): void {
    this.subscriptions.add(
      this.modulesService.dataStore.subscribe((data) => {
        this.modulesInformation = data;
        this.setMenuItems();
      })
    );
  }

  getAuthorizationSubMenu(): MenuItem[] {
    const allMenu: MenuItem[] = [
      {
        key: MODULES.AUTHORIZATION_PERMISSIONS,
        searchKey: 'authorization permissions',
        icon: 'local_police',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.permissions-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.permissions-tooltip'
        ),
        link: '/app/authorization/permissions',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(
          MODULES.AUTHORIZATION_PERMISSIONS,
          [PERMISSION_ACTIONS.READ]
        ),
      },
      {
        key: MODULES.AUTHORIZATION_ROLES,
        searchKey: 'authorization roles',
        icon: 'account_circle',
        face: this.translate.instant('pages.dashboard.sub-menu.roles-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.roles-tooltip'
        ),
        link: '/app/authorization/roles',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.AUTHORIZATION_ROLES, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.AUTHORIZATION_PRIVILEGES,
        searchKey: 'authorization privileges',
        icon: 'luggage',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.privileges-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.privileges-tooltip'
        ),
        link: '/app/authorization/privileges',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(
          MODULES.AUTHORIZATION_PRIVILEGES,
          [PERMISSION_ACTIONS.READ]
        ),
      },
    ];

    return allMenu.filter(this.filterMenu);
  }

  getSetupSubMenu() {
    const allMenu: MenuItem[] = [
      {
        key: `SETUP_GENERAL`,
        searchKey: 'setup general',
        icon: 'settings',
        face: this.translate.instant('pages.dashboard.sub-menu.general-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.general-tooltip'
        ),
        link: '/app/setup/general',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_IAM`,
        searchKey: 'setup_iam',
        icon: 'local_police',
        face: this.translate.instant('pages.dashboard.sub-menu.iam-face'),
        tooltip: this.translate.instant('pages.dashboard.sub-menu.iam-tooltip'),
        link: '/app/setup/identity-and-access',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_REFERENCE_MAPPING`,
        searchKey: 'setup_reference_mapping',
        icon: 'link',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.reference-mapping-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.reference-mapping-tooltip'
        ),
        link: '/app/setup/reference-mapping',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_STORAGE`,
        searchKey: 'setup_storage',
        icon: 'storage',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.storage-config-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.storage-config-tooltip'
        ),
        link: '/app/setup/storage',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_APPEARANCE`,
        searchKey: 'setup appearance',
        icon: 'palette',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.appearance-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.appearance-tooltip'
        ),
        link: '/app/setup/appearance',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_LANGUAGES`,
        searchKey: 'setup languages',
        icon: 'translate',
        face: this.translate.instant('pages.dashboard.sub-menu.languages-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.languages-tooltip'
        ),
        link: '/app/setup/languages',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_CURRENCIES`,
        searchKey: 'setup currencies',
        icon: 'attach_money',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.currencies-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.currencies-tooltip'
        ),
        link: '/app/setup/currencies',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_NOTIFICATIONS`,
        searchKey: 'setup notifications',
        icon: 'notifications',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.notifications-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.notifications-tooltip'
        ),
        link: '/app/setup/notifications',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_MESSAGES`,
        searchKey: 'setup messages',
        icon: 'messages',
        face: this.translate.instant('pages.dashboard.sub-menu.messages-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.messages-tooltip'
        ),
        link: '/app/setup/messages',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_SECURITY`,
        searchKey: 'setup security',
        icon: 'local_police',
        face: this.translate.instant('pages.dashboard.sub-menu.security-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.security-tooltip'
        ),
        link: '/app/setup/security',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_RELEASES`,
        searchKey: 'setup releases',
        icon: 'new_releases',
        face: this.translate.instant('pages.dashboard.sub-menu.releases-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.releases-tooltip'
        ),
        link: '/app/setup/releases',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_SUPPORT`,
        searchKey: 'setup support',
        icon: 'support_agent',
        face: this.translate.instant('pages.dashboard.sub-menu.support-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.support-tooltip'
        ),
        link: '/app/setup/support',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_MAINTENANCE`,
        searchKey: 'setup maintenance',
        icon: 'engineering',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.maintenance-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.maintenance-tooltip'
        ),
        link: '/app/setup/maintenance',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_DEBUGGING`,
        searchKey: 'setup debugging',
        icon: 'bug_report',
        face: this.translate.instant('pages.dashboard.sub-menu.debugging-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.debugging-tooltip'
        ),
        link: '/app/setup/debugging',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_PWA`,
        searchKey: 'progressive web app pwa',
        icon: 'system_update',
        face: this.translate.instant('pages.dashboard.sub-menu.pwa-face'),
        tooltip: this.translate.instant('pages.dashboard.sub-menu.pwa-tooltip'),
        link: '/app/setup/progressive-web-app',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_BACKUPS`,
        searchKey: 'setup backups',
        icon: 'backups',
        face: this.translate.instant('pages.dashboard.sub-menu.backups-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.backups-tooltip'
        ),
        link: '/app/setup/backups',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_EXTERNAL_SYSTEMS`,
        searchKey: 'setup external systems',
        icon: 'branding_watermark',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.external-systems-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.external-systems-tooltip'
        ),
        link: '/app/setup/external-systems',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_URL_SHORTENER`,
        searchKey: 'setup url shortener',
        icon: 'link',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.url-shortener-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.external-systems-tooltip'
        ),
        link: '/app/setup/url-shortener',
        type: DLT_DATA_TYPES.LINK,
      },
      {
        key: `SETUP_SYSTEM_VARIABLES`,
        searchKey: 'setup system variables',
        icon: 'bolt',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.system-variables-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.system-variables-tooltip'
        ),
        link: '/app/setup/system-variables',
        type: DLT_DATA_TYPES.LINK,
      },
    ];

    return allMenu.filter(this.filterMenu);
  }

  getStorageSubMenu() {
    const allMenu: MenuItem[] = [
      {
        key: `STORAGE_DRIVE`,
        searchKey: 'storage drive',
        icon: 'storage',
        face: this.translate.instant('pages.dashboard.sub-menu.drive-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.drive-tooltip'
        ),
        link: '/app/storage/drives',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.STORAGE_DRIVE, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: `STORAGE_RECYCLE_BIN`,
        searchKey: 'storage recycle bin',
        icon: 'delete',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.recycle-bin-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.recycle-bin-tooltip'
        ),
        link: '/app/storage/trash',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.STORAGE_RECYCLE_BIN, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
    ];

    return allMenu.filter(this.filterMenu);
  }

  getReferencesSubMenu(): MenuItem[] {
    const allMenu: MenuItem[] = [];
    const deletedReferences: MenuItem[] = [];

    if (this.fetchedReferenceList || this.referenceCategories.length > 0) {
      const groupedCategories = groupBy(this.referenceCategories, (category) =>
        category.is_deleted
          ? this.translate.instant(
              'pages.dashboard.sub-menu.deleted-references'
            )
          : category.section ||
            this.translate.instant(
              'pages.dashboard.sub-menu.uncategorized-references'
            )
      );

      Object.entries(groupedCategories).forEach(
        ([sectionHeader, referenceCategories]: [
          string,
          IReferenceCategoryResponse[]
        ]) => {
          if (sectionHeader != 'Deleted') {
            {
              const filteredReferenceCategories =
                this.loggedUserInfo.connection.permissions.includes(
                  generatePermissionKey(
                    MODULES.REFERENCES,
                    REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY
                  )
                )
                  ? referenceCategories
                  : referenceCategories.filter(
                      (category) =>
                        validateReferenceModulePermissions(
                          this.loggedUserInfo,
                          [category],
                          REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY
                        ) !== 'none'
                    );
              const sectionMenu: MenuItem[] = filteredReferenceCategories.map(
                (category) => ({
                  key: `REFERENCE_CATEGORY_${category._id}`,
                  searchKey: category.name,
                  icon: 'insert_link',
                  face: category.name,
                  tooltip: category.description || category.name,
                  link: `/app/reference-categories/${category._id}/references`,
                  type: DLT_DATA_TYPES.LINK,
                })
              );

              const otherCategories: MenuItem[] = [
                {
                  key: sectionHeader,
                  searchKey: '',
                  icon: '',
                  face: sectionHeader,
                  tooltip: '',
                  link: '',
                  loading: false,
                  type: 'section',
                },
                ...sectionMenu,
              ];

              allMenu.push(...otherCategories);
            }
          } else {
            {
              const filteredReferenceCategories =
                this.loggedUserInfo.connection.permissions.includes(
                  generatePermissionKey(
                    MODULES.REFERENCES,
                    REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY
                  )
                )
                  ? referenceCategories
                  : referenceCategories.filter(
                      (category) =>
                        validateReferenceModulePermissions(
                          this.loggedUserInfo,
                          [category],
                          REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY
                        ) !== 'none'
                    );
              const sectionMenu: MenuItem[] = filteredReferenceCategories.map(
                (category) => ({
                  key: `REFERENCE_CATEGORY_${category._id}`,
                  searchKey: category.name,
                  icon: 'insert_link',
                  face: category.name,
                  tooltip: category.description || category.name,
                  link: `/app/reference-categories/${category._id}/references`,
                  type: DLT_DATA_TYPES.LINK,
                })
              );

              const deletedCategories: MenuItem[] = [
                {
                  key: sectionHeader,
                  searchKey: '',
                  icon: '',
                  face: sectionHeader,
                  tooltip: '',
                  link: '',
                  loading: false,
                  type: 'section',
                },
                ...sectionMenu,
              ];

              deletedReferences.push(...deletedCategories);
            }
          }
        }
      );
    } else {
      allMenu.push({
        key: 'NO_REFERENCE_CATEGORIES',
        searchKey: '',
        icon: 'edit',
        face: this.translate.instant('pages.dashboard.sub-menu.loading'),
        link: '',
        loading: true,
        type: DLT_DATA_TYPES.LINK,
      });
    }

    if (
      this.permissionFiltrationService.validatePermissions({
        permissions: [
          generatePermissionKey(
            MODULES.REFERENCES,
            REFERENCE_PERMISSION_ACTIONS.WRITE_CATEGORY
          ),
        ],
      })
    ) {
      allMenu.unshift({
        key: 'REFERENCE_CATEGORY_ADD',
        face: this.translate.instant(
          'pages.dashboard.sub-menu.references-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.references-tooltip'
        ),
        type: 'button',
        searchKey: '',
        icon: '',
        link: '',
      });
    }

    allMenu.push(...deletedReferences);

    return allMenu;
  }

  getIntegrationsSubMenu() {
    const allMenu: MenuItem[] = [
      {
        key: `ENDPOINTS`,
        searchKey: 'endpoints',
        icon: 'integration_instructions',
        face: this.translate.instant('pages.dashboard.sub-menu.endpoints-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.endpoints-tooltip'
        ),
        link: '/app/integrations/endpoints',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.INTEGRATIONS, [
          INTEGRATION_PERMISSION_ACTIONS.READ_ENDPOINTS,
        ]),
      },
      {
        key: `MAPPING`,
        searchKey: 'mapping',
        icon: 'display_settings',
        face: this.translate.instant('pages.dashboard.sub-menu.mapping-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.sub-menu.mapping-tooltip'
        ),
        link: '/app/integrations/mappings',
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.INTEGRATIONS, [
          INTEGRATION_PERMISSION_ACTIONS.READ_MAPPINGS,
        ]),
      },
    ];

    return allMenu.filter(this.filterMenu);
  }

  getCoreMenu(): MenuItem[] {
    const authorizationSubMenu = this.getAuthorizationSubMenu();
    const setupSubMenu = this.getSetupSubMenu();
    const storageSubMenu = this.getStorageSubMenu();
    const referencesSubMenu = this.getReferencesSubMenu();
    const integrationsSubMenu = this.getIntegrationsSubMenu();

    const expansionCheck = (child: MenuItem) => {
      return this.router.url.indexOf(child.link) === 0;
    };

    const allMenu: MenuItem[] = [
      {
        key: MODULES.MODULES,
        searchKey: 'modules',
        icon: 'extension',
        face: this.translate.instant('pages.dashboard.menu-items.modules-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.modules-tooltip'
        ),
        link: '/app/modules',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.MODULES, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.APPS,
        searchKey: 'apps',
        icon: 'laptop',
        face: this.translate.instant('pages.dashboard.menu-items.apps-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.apps-tooltip'
        ),
        link: '/app/apps',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.APPS, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: 'AUTHORIZATION',
        searchKey: 'authorization',
        icon: 'local_police',
        face: this.translate.instant(
          'pages.dashboard.menu-items.authorization-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.authorization-tooltip'
        ),
        link: '/app/authorization',
        loading: false,
        type: 'group',
        expanded: authorizationSubMenu.some(expansionCheck),
        children: authorizationSubMenu.map((child) => ({
          ...child,
          loading: false,
          type: child.type === 'section' ? 'section' : 'link',
        })),
      },
      {
        key: MODULES.INTEGRATIONS,
        searchKey: 'integrations',
        icon: 'integration_instructions',
        face: this.translate.instant(
          'pages.dashboard.menu-items.integrations-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.integrations-tooltip'
        ),
        link: '/app/integrations',
        loading: false,
        type: 'group',
        expanded: integrationsSubMenu.some(expansionCheck),
        children: integrationsSubMenu.map((child) => ({
          ...child,
          loading: false,
          type: child.type === 'section' ? 'section' : 'link',
        })),
      },
      {
        key: MODULES.IDENTITIES,
        searchKey: 'identities',
        icon: 'person',
        face: this.translate.instant(
          'pages.dashboard.menu-items.identities-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.identities-tooltip'
        ),
        link: '/app/identities',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.CONNECTIONS,
        searchKey: 'connections',
        icon: 'connect_without_contact',
        face: this.translate.instant(
          'pages.dashboard.menu-items.connections-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.connections-tooltip'
        ),
        link: '/app/connections',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.CONNECTIONS, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.MESSAGES,
        searchKey: 'messages',
        icon: 'message',
        face: this.translate.instant(
          'pages.dashboard.menu-items.messages-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.messages-tooltip'
        ),
        link: '/app/messages',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.MESSAGES, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: 'STORAGE',
        searchKey: 'storage',
        icon: 'storage',
        face: this.translate.instant(
          'pages.dashboard.menu-items.storages-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.storages-tooltip'
        ),
        link: '/app/storage',
        loading: false,
        type: 'group',
        expanded: storageSubMenu.some(expansionCheck),
        children: storageSubMenu.map((child) => ({
          ...child,
          loading: false,
          type: child.type === 'section' ? 'section' : 'link',
        })),
      },
      {
        key: MODULES.NOTICES,
        searchKey: 'notices',
        icon: 'newspaper',
        face: this.translate.instant('pages.dashboard.menu-items.notices-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.notices-tooltip'
        ),
        link: '/app/notices',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.NOTICES, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.NOTIFICATIONS,
        searchKey: 'notifications',
        icon: 'notifications',
        face: this.translate.instant(
          'pages.dashboard.menu-items.notifications-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.notifications-tooltip'
        ),
        link: '/app/notifications',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.NOTIFICATIONS, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.REFERENCES,
        searchKey: 'references',
        icon: 'insert_link',
        face: this.translate.instant(
          'pages.dashboard.menu-items.references-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.references-tooltip'
        ),
        link: '/app/references',
        loading: false,
        type: 'group',
        expanded: referencesSubMenu.some(expansionCheck),
        children: referencesSubMenu,
        params: { is_search: true },
      },
      {
        key: MODULES.KNOWLEDGEBASE,
        searchKey: 'knowledgebase',
        icon: 'library_books',
        face: this.translate.instant(
          'pages.dashboard.menu-items.knowledgebase-face'
        ),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.knowledgebase-tooltip'
        ),
        link: '/app/knowledgebase',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.KNOWLEDGEBASE, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.LOGS,
        searchKey: 'logs',
        icon: 'fact_check',
        face: this.translate.instant('pages.dashboard.menu-items.logs-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.logs-tooltip'
        ),
        link: '/app/logs',
        loading: false,
        type: DLT_DATA_TYPES.LINK,
        params: { is_search: true },
        allowedPermissions: getModulePermission(MODULES.LOGS, [
          PERMISSION_ACTIONS.READ,
        ]),
      },
      {
        key: MODULES.SETUP,
        searchKey: 'system setup',
        icon: 'settings',
        face: this.translate.instant('pages.dashboard.menu-items.setup-face'),
        tooltip: this.translate.instant(
          'pages.dashboard.menu-items.setup-tooltip'
        ),
        link: '/app/setup',
        loading: false,
        type: 'group',
        expanded: setupSubMenu.some(expansionCheck),
        children: setupSubMenu.map((child) => ({
          ...child,
          loading: false,
        })),
      },
    ];

    let visible = allMenu.filter(this.filterMenu);
    this.sideMenuService.addToVisibleItems(visible);

    if (!this.isInternalIAM) {
      // modules that get hidden when on external IAM
      const restrictedModules = ['IDENTITIES', 'APPS', 'CONNECTIONS'];

      visible = visible.filter(
        (module) => !restrictedModules.includes(module.key)
      );
    }

    if (!this.isInternalStorage) {
      // modules that get hidden when on external storage
      visible = visible.filter((module) => module.key !== 'STORAGE');
    }

    if (visible.length > 0) {
      return [
        {
          key: 'CORE',
          searchKey: '',
          icon: '',
          face: this.translate.instant('pages.dashboard.menu-items.core'),
          tooltip: '',
          link: '',
          loading: false,
          type: 'section',
        },
        ...visible,
      ];
    }

    return [];
  }

  onExpand() {
    this.btnVisible = !this.btnVisible;
  }

  itemSelect(menuItem: MenuItem) {
    if (!menuItem.children) {
      this.onMenuItemClicked(menuItem);
      this.isItemSelected.emit(true);
    }
  }

  itemExpanded(menuItem: MenuItem) {
    if (menuItem.key === MODULES.REFERENCES) {
      if (!this.fetchedReferenceList && this.referenceCategories.length === 0) {
        this.fetchedReferenceList = true;
        this.referenceCategoryService.getAllReferenceCategories();
      }
    }
  }

  onClickLaunchPad() {
    this.isItemSelected.emit(true);
  }
  logoErrorHandler(event: { target: EventTarget }) {
    const target = event.target as HTMLImageElement;
    target.src = '/assets/not-found-placeholder.svg';
  }

  private setMenuItems(): void {
    this.menuItems = [];
    if (this.modulesInformation && this.loggedUserInfo) {
      this.menuItems = [...this.getCoreMenu()];
      this.setupSectionSearchStrings(this.menuItems);
      this.menuItems.forEach((element) => {
        if (element.children) {
          this.setupSectionSearchStrings(element.children);
        }
      });
    }
  }

  setupSectionSearchStrings(menuItems: MenuItem[]) {
    let currentSection = null;
    menuItems.forEach((item) => {
      if (item.type === 'section') {
        currentSection = item;
        currentSection.searchKey = currentSection.searchKey + ' ' + item.face;
      } else {
        if (currentSection) {
          if (item.children) {
            item.children.forEach((child) => {
              currentSection.searchKey =
                currentSection.searchKey + ' ' + child.face;
              child.searchKey = child.searchKey + ' ' + currentSection.face;
            });
          }
          currentSection.searchKey = currentSection.searchKey + ' ' + item.face;
          item.searchKey = item.searchKey + ' ' + currentSection.face;
        }
      }
    });
  }

  onMenuItemClicked(menuItem: MenuItem) {
    if (menuItem.key === 'REFERENCE_CATEGORY_ADD') {
      this.referenceCategoryService.addNewCategory().then((newCategoryId) => {
        if (newCategoryId) {
          this.router.navigate([
            `/app/reference-categories/${newCategoryId}/references`,
          ]);
        }
      });
    } else {
      this.router.navigate([menuItem.link], { queryParams: menuItem.params });
    }
  }
}
