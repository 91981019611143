import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';

import {
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  REFERENCE_CATEGORY_TYPES,
  SYSTEM_GENERATED_REFERENCE_SECTIONS,
} from '@shared/constants';
import {
  IConfigurableFieldConfigCheckBox,
  IConfigurableFieldConfigRequest,
  IHelpAndSupport,
  IKeyValuePair,
  IReferenceCategoryRequest,
  IReferenceCategoryResponse,
  IReferenceResponse,
} from '@shared/interfaces';
import { isConstant, setBackCheckboxes } from '@shared/utils';

import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import Base64UploaderPlugin from '../../../../utils/Base64Upload';
import { ConfigureConfigurableFieldService } from '../../../configurable-fields/services';
import { IConfigureConfigurableFieldElement } from '../../../configurable-fields/types';
import { noWhitespaceValidator } from '../../../core/helpers/form-field-white-space-validator';
import { ReferenceService } from '../../services/reference.service';
import { ReferenceCategoryService } from '../../services/reference-category.service';

const blockedReferenceSections = Object.values(
  SYSTEM_GENERATED_REFERENCE_SECTIONS
).map(String);
@Component({
  selector: 'app-add-reference-category-popup',
  templateUrl: 'add-reference-category-popup.component.html',
  styleUrls: ['add-reference-category-popup.component.scss'],
})
export class AddReferenceCategoryComponent implements OnInit, OnDestroy {
  REFERENCE_CATEGORY_TYPES = REFERENCE_CATEGORY_TYPES;

  fieldTypesOptions: IKeyValuePair<string>[] = Object.entries(
    CONFIGURABLE_FIELD_DATA_TYPES
  )
    .filter(
      ([, value]) =>
        ![
          CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX,
          CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON,
          CONFIGURABLE_FIELD_DATA_TYPES.NONE,
        ].includes(value)
    )
    .map(([key, value]) => ({
      value: value.toString(),
      key: key.replace(/_/g, ' '),
    }));

  title: string;
  editMode = false;
  hasChildren = false;
  formDataChanges = false;
  fieldRemoved = false;
  isAnyFieldsInvalid = false;
  isAllFieldPristine = true;
  isAllCheckBoxesPristine = true;
  isLoading = false;

  private onDestroy$ = new Subject<void>();
  private sectionList: string[] = [];
  private readonly defaultCheckboxes: IConfigurableFieldConfigCheckBox[] = [
    {
      key: this.translate.instant('configurable-fields.checkboxes.sort'),
      isChecked: false,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.SORT,
    },
    {
      key: this.translate.instant('configurable-fields.checkboxes.required'),
      isChecked: true,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
    },
    {
      key: this.translate.instant('configurable-fields.checkboxes.constant'),
      isChecked: false,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.CONSTANT,
    },
    {
      key: this.translate.instant('configurable-fields.checkboxes.unique'),
      isChecked: false,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
    },
    {
      key: this.translate.instant('configurable-fields.checkboxes.default'),
      isChecked: true,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT,
    },
  ];
  referenceTypes = [
    {
      value: REFERENCE_CATEGORY_TYPES.INTERNAL,
      viewValue: 'Internal Reference',
    },
    {
      value: REFERENCE_CATEGORY_TYPES.EXTERNAL,
      viewValue: 'External Reference',
    },
  ];
  activeReferenceCategories: IReferenceCategoryResponse[] = [];
  activeReferenceCategoryNames: string[] = [];
  filteredSectionList: Observable<string[]>;

  referenceCategoryFormGroup = new FormGroup({
    section: new FormControl(''),
    category_name: new FormControl('', [
      Validators.required,
      noWhitespaceValidator,
    ]),
    category_description: new FormControl('', Validators.required),
    publicReference: new FormControl(false),
    helpAndSupport: new FormArray([
      new FormGroup({
        help_and_support_title: new FormControl(null, [
          Validators.required,
          noWhitespaceValidator,
        ]),
        help_and_support_description: new FormControl(null, [
          Validators.required,
        ]),
      }),
    ]),

    reference_type: new FormControl(
      REFERENCE_CATEGORY_TYPES.INTERNAL,
      Validators.required
    ),
    connected_system: new FormControl('', Validators.required),
    connected_category: new FormControl('', Validators.required),
  });

  fields: IConfigureConfigurableFieldElement[] = [];
  editor = ClassicEditor;
  public editorConfig = {
    extraPlugins: [Base64UploaderPlugin],
    removePlugins: [
      'Image',
      'ImageToolbar',
      'ImageCaption',
      'ImageStyle',
      'ImageResize',
      'ImageUpload',
      'EasyImage',
      'CKFinder',
      'Table',
      'TableToolbar',
      'TableProperties',
      'TableCellProperties',
      'MediaEmbed',
    ],
  };

  constructor(
    private dialogRefCategory: MatDialogRef<AddReferenceCategoryComponent>,
    private referenceService: ReferenceService,
    private referenceCategoryService: ReferenceCategoryService,
    private configureConfigurableFieldService: ConfigureConfigurableFieldService,
    private translate: TranslateService,
    private snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA)
    public referenceCategory: IReferenceCategoryResponse
  ) {
    this.editMode = !!referenceCategory;
    if (this.editMode) {
      this.title = 'Edit Category';
    } else {
      this.title = 'Add New Category';
    }
  }

  ngOnInit(): void {
    this.referenceCategoryService.activeCategories
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((categories) => {
        const categoryNamesList: string[] = [];
        const sectionList: string[] = [];

        categories.forEach((category) => {
          categoryNamesList.push(category.name);
          if (
            category.section &&
            !blockedReferenceSections.includes(category.section)
          ) {
            sectionList.push(category.section);
          }
        });

        this.activeReferenceCategories = categories;
        this.sectionList = Array.from(new Set(sectionList)).sort((a, b) =>
          a > b ? 1 : -1
        );
        this.activeReferenceCategoryNames = Array.from(
          new Set(categoryNamesList)
        ).sort((a, b) => (a > b ? 1 : -1));
      });

    this.initForm();

    this.filteredSectionList =
      this.referenceCategoryFormGroup.controls.section.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const filterValue = value.toLowerCase();

          return this.sectionList.filter(
            (option) => option.toLowerCase().indexOf(filterValue) === 0
          );
        })
      );

    this.referenceCategoryFormGroup.controls.reference_type.setValue(
      REFERENCE_CATEGORY_TYPES.INTERNAL
    );

    this.referenceCategoryFormGroup.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes) => {
        if (changes) {
          this.formDataChanges = true;
        }
      });
    this.addHelpAndSupport();
  }

  addHelpAndSupport() {
    if (!this.editMode) {
      this.helpAndSupportFormGroup.removeAt(0);
      this.helpAndSupportFormGroup.push(
        new FormGroup({
          help_and_support_title: new FormControl(
            this.translate.instant('references.reference-list.help-card-title'),
            [Validators.required]
          ),
          help_and_support_description: new FormControl(
            this.translate.instant(
              'references.reference-list.help-card-paragraph-one'
            ) +
              '<br><br>' +
              this.translate.instant(
                'references.reference-list.help-card-paragraph-two'
              ) +
              '<br><br>' +
              this.translate.instant(
                'references.reference-list.help-card-paragraph-three'
              ),
            [Validators.required]
          ),
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async onCheckCategoryAdd() {
    const category_name =
      this.referenceCategoryFormGroup.get('category_name')?.value;
    const existingCategory = this.activeReferenceCategoryNames.find(
      (category) => category === category_name
    );

    if (existingCategory) {
      this.referenceCategoryFormGroup?.get('category_name')?.markAsTouched();
      this.referenceCategoryFormGroup.controls['category_name'].setErrors({
        sameName: true,
      });
    }
  }

  private async initForm() {
    if (this.editMode) {
      const splitValues: string[] = [];

      for (
        let index = 0;
        index < this.helpAndSupportFormArray.length;
        index++
      ) {
        if (
          !splitValues.includes(
            this.helpAndSupportFormArray[index].controls.help_and_support_title
              .value
          )
        ) {
          this.helpAndSupportFormGroup.removeAt(index);
        }
      }

      this.hasChildren =
        this.editMode &&
        (
          await this.referenceService.getReferences(
            this.referenceCategory._id.toString()
          )
        ).length > 0;

      this.referenceCategoryFormGroup.controls.section.patchValue(
        this.referenceCategory.section
      );
      this.referenceCategoryFormGroup.controls.category_name.patchValue(
        this.referenceCategory.name
      );
      this.referenceCategoryFormGroup.controls.category_description.patchValue(
        this.referenceCategory.description
      );

      if (this.referenceCategory.is_public) {
        this.referenceCategoryFormGroup.controls.publicReference.patchValue(
          this.referenceCategory.is_public
        );
      } else {
        this.referenceCategoryFormGroup.controls.publicReference.patchValue(
          false
        );
      }

      if (this.referenceCategory.helpAndSupport?.length > 0) {
        this.referenceCategory.helpAndSupport.forEach((help) => {
          this.helpAndSupportFormGroup.push(
            new FormGroup({
              help_and_support_title: new FormControl(
                help.help_and_support_title,
                [Validators.required, noWhitespaceValidator]
              ),
              help_and_support_description: new FormControl(
                help.help_and_support_description,
                [Validators.required]
              ),
            })
          );
        });
      } else {
        this.helpAndSupportFormGroup.push(
          new FormGroup({
            help_and_support_title: new FormControl(
              this.translate.instant(
                'references.reference-list.help-card-title'
              ),
              [Validators.required]
            ),
            help_and_support_description: new FormControl(
              this.translate.instant(
                'references.reference-list.help-card-paragraph-one'
              ) +
                '<br><br>' +
                this.translate.instant(
                  'references.reference-list.help-card-paragraph-two'
                ) +
                '<br><br>' +
                this.translate.instant(
                  'references.reference-list.help-card-paragraph-three'
                ),
              [Validators.required]
            ),
          })
        );
      }

      this.fields =
        await this.configureConfigurableFieldService.generateFormFields(
          this.referenceCategory.fields,
          {
            defaultCheckboxes: this.defaultCheckboxes,
            disableMetaFields: this.hasChildren,
            allowWhiteSpacesInName: true,
            referenceCategories: this.activeReferenceCategories,
            validityChecker: {
              validateUntil: this.onDestroy$.asObservable(),
              validate: () => this.checkValidity(),
            },
          }
        );

      this.fields.forEach((x, i) => {
        if (x.type.value === CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES)
          this.setReferenceValues(i);
      });
    }

    if (!this.hasChildren && this.fields.length === 0) {
      this.addForm();
    }
  }

  get helpAndSupportFormGroup() {
    return this.referenceCategoryFormGroup.controls.helpAndSupport;
  }

  isReference(index: number) {
    return (
      this.fields[index]?.type.value ===
      CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES
    );
  }

  async setReferenceValues(index: number) {
    const { selectedCategory, reference_field } = this.fields[index];

    this.fields[index].referencesForSelectedField = (
      await this.referenceService.getReferences(selectedCategory._id.toString())
    ).map((child) => {
      const { reference } = child;
      // TODO:@sampath centralize the field-to-string mechanism into one place
      const returnValue: ELAutocompleteElement = {
        value: reference
          .find((r) => r.field_id.toString() === reference_field.value)
          ?.value?.toString?.(),
      };
      return returnValue;
    });
  }

  getFieldValue(ref: IReferenceResponse, field_id: string): string {
    // TODO:@sampath centralize the field-to-string mechanism into one place
    return ref.reference
      .find((r) => r.field_id.toString() === field_id)
      ?.value?.toString?.();
  }

  addForm() {
    const name = new FormControl('', [
      Validators.required,
      noWhitespaceValidator,
    ]);
    const fieldType = new FormControl(
      CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      Validators.required
    );
    const referenceCategory = new FormControl('');
    const referenceField = new FormControl('');
    const defaultValue = new FormControl('');

    name.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.checkValidity());
    fieldType.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.checkValidity());
    referenceCategory.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.checkValidity());
    referenceField.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.checkValidity());
    defaultValue.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.checkValidity());

    this.fields.push({
      name,
      mandatory: false,
      type: fieldType,
      reference_category: referenceCategory,
      reference_field: referenceField,
      displayTheDefaultFieldItem: {
        field_value: defaultValue,
        configuration: {
          name: 'Default Value',
          type: fieldType.value,
        },
      },
      checkboxes: cloneDeep(this.defaultCheckboxes),
      selectedCategory: undefined,
      referencesForSelectedField: [],
      additionalFormFields: [],
    });
    this.checkValidity();
  }

  addSection() {
    const newHelpAndSupport = new FormGroup({
      help_and_support_title: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      help_and_support_description: new FormControl('', [Validators.required]),
    });
    (this.referenceCategoryFormGroup.get('helpAndSupport') as FormArray).push(
      newHelpAndSupport
    );
  }

  removeHelpAndSupport(index: number) {
    (
      this.referenceCategoryFormGroup.get('helpAndSupport') as FormArray
    ).removeAt(index);
  }

  get helpAndSupportFormArray() {
    return this.referenceCategoryFormGroup.controls.helpAndSupport.controls;
  }

  onFieldDeleted() {
    this.fieldRemoved = true;
  }

  checkValidity() {
    this.isAnyFieldsInvalid = this.fields.some(
      (item, i) =>
        item.name.invalid ||
        (this.isReference(i) &&
          (!item.selectedCategory ||
            item.reference_category.invalid ||
            item.reference_field.invalid ||
            item.reference_field.disabled))
    );

    this.isAllFieldPristine = this.fields.every(
      (item) =>
        item.displayTheDefaultFieldItem.field_value.pristine &&
        item.type.pristine &&
        item.name.pristine &&
        item.reference_field.pristine &&
        item.reference_category.pristine
    );
  }

  onClickCheckBox(event: { fieldIndex: number; checkboxIndex: number }) {
    this.isAllCheckBoxesPristine = false;

    const clickedCheckbox =
      this.fields[event.fieldIndex].checkboxes[event.checkboxIndex];

    const defaultCheckbox = this.fields[event.fieldIndex].checkboxes.find(
      (checkbox) => checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT
    );

    const uniqueCheckbox = this.fields[event.fieldIndex].checkboxes.find(
      (checkbox) => checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE
    );

    const constantCheckbox = this.fields[event.fieldIndex].checkboxes.find(
      (checkbox) => checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.CONSTANT
    );

    if (
      uniqueCheckbox &&
      clickedCheckbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE
    ) {
      if (clickedCheckbox.isChecked) {
        constantCheckbox.isChecked = false;
        constantCheckbox.disabled = true;
      } else {
        constantCheckbox.disabled = false;
      }
    }

    if (
      constantCheckbox &&
      clickedCheckbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.CONSTANT
    ) {
      if (clickedCheckbox.isChecked) {
        uniqueCheckbox.isChecked = false;
        uniqueCheckbox.disabled = true;
      } else {
        uniqueCheckbox.disabled = false;
      }
    }

    if (
      defaultCheckbox &&
      clickedCheckbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED
    ) {
      if (clickedCheckbox.isChecked) {
        defaultCheckbox.isChecked = true;
        defaultCheckbox.disabled = true;
      } else {
        defaultCheckbox.disabled = false;
      }
    }
  }

  save() {
    if (
      blockedReferenceSections.includes(
        this.referenceCategoryFormGroup.controls.section.value
      )
    ) {
      this.snackBar.error('System created section names not allowed.');
      return;
    }
    let errorInFields = false;
    let fields: IConfigurableFieldConfigRequest[];

    if (
      this.referenceCategoryFormGroup.controls.reference_type.value ===
      REFERENCE_CATEGORY_TYPES.INTERNAL
    ) {
      fields = this.fields.map((field, i) => {
        if (
          !!field.name.value &&
          field.name.value.toString().trim() !== '' &&
          !!field.type.value &&
          field.type.value.toString().trim() !== '' &&
          (!this.isReference(i) ||
            (!!field.reference_category.value &&
              field.reference_category.value.toString().trim() !== '' &&
              !!field.reference_field.value &&
              field.reference_field.value.toString().trim() !== '')) &&
          (!isConstant(field?.checkboxes) ||
            field.displayTheDefaultFieldItem.field_value.value
              .toString()
              .trim() !== '')
        ) {
          const checkboxes = setBackCheckboxes(
            this.defaultCheckboxes,
            field?.checkboxes
          );

          return {
            reference_category: field.selectedCategory
              ? field.reference_category.value
              : undefined,
            reference_field: field.selectedCategory
              ? field.reference_field.value
              : undefined,
            type: field.type.value,
            name: field.name.value,
            default_value: field.displayTheDefaultFieldItem.field_value.value,
            checkboxes,
            reference_type_field_config:
              this.isReference(i) && field.selectedCategory
                ? {
                    category_id: field.reference_category.value,
                    field_id: field.reference_field.value,
                  }
                : undefined,
            additional_fields: [],
          };
        } else {
          errorInFields = true;
          return null;
        }
      });
    }

    if (
      !!this.referenceCategoryFormGroup.controls.category_name.value &&
      this.referenceCategoryFormGroup.controls.category_name.value
        .toString()
        .trim() !== '' &&
      !!this.referenceCategoryFormGroup.controls.category_description.value &&
      this.referenceCategoryFormGroup.controls.category_description.value
        .toString()
        .trim() !== '' &&
      !errorInFields
    ) {
      const helpAndSupportArray: IHelpAndSupport[] = (
        this.referenceCategoryFormGroup.controls.helpAndSupport as FormArray
      ).controls
        .map((helpAndSupportGroup: FormGroup) => ({
          help_and_support_title:
            helpAndSupportGroup.controls.help_and_support_title.value,
          help_and_support_description:
            helpAndSupportGroup.controls.help_and_support_description.value,
        }))
        .filter(
          (item: IHelpAndSupport) =>
            item.help_and_support_title !== '' &&
            item.help_and_support_description !== ''
        );

      const category: IReferenceCategoryRequest = {
        section: this.referenceCategoryFormGroup.controls.section.value,
        name: this.referenceCategoryFormGroup.controls.category_name.value,
        description:
          this.referenceCategoryFormGroup.controls.category_description.value,
        fields,
        helpAndSupport: helpAndSupportArray,
        external_system:
          this.referenceCategoryFormGroup.controls.connected_system.value,
        external_category:
          this.referenceCategoryFormGroup.controls.connected_category.value,
        type: this.referenceCategoryFormGroup.controls.reference_type.value,
        is_public:
          this.referenceCategoryFormGroup.controls.publicReference.value,
      };
      this.formDataChanges = false;
      this.dialogRefCategory.close(category);
      if (this.editMode === false) {
        this.snackBar.success(
          SUCCESS_TYPES.ADDED,
          this.translate.instant(
            'references.add-new-reference-category.reference-category'
          )
        );
      } else {
        this.snackBar.success(
          SUCCESS_TYPES.UPDATED,
          this.translate.instant(
            'references.add-new-reference-category.reference-category'
          )
        );
      }
    } else {
      this.snackBar.error(
        this.translate.instant('references.add-new-reference-category.error')
      );
    }
  }
}
