import {
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
} from '../configurable-fields';

import { REFERENCE_TYPES } from './enums';

export * from './enums';

// This array is maintained to exclude certain field types from reference bulk upload until validations are added for each one.
// However, some fields cannot be used inside the CSV. Therefore, those fields will remain in the array below.
// If there is any field here within the reference category fields, then its default value will be saved and it will be handled in the bulk uploading endpoint.
export const excludedFieldTypes: CONFIGURABLE_FIELD_DATA_TYPES[] = [
  CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY,
  CONFIGURABLE_FIELD_DATA_TYPES.DATE,
  CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN,
  CONFIGURABLE_FIELD_DATA_TYPES.FORMULA,
  CONFIGURABLE_FIELD_DATA_TYPES.VIEW,
  CONFIGURABLE_FIELD_DATA_TYPES.JSON,
  CONFIGURABLE_FIELD_DATA_TYPES.FILE,
  CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT,
  CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS,
  CONFIGURABLE_FIELD_DATA_TYPES.NAME,
  CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER,
  CONFIGURABLE_FIELD_DATA_TYPES.EMAIL,
  CONFIGURABLE_FIELD_DATA_TYPES.TIME,
];

export const predefinedReferenceFieldMapping: {
  [ref_name: string]: {
    [field_name: string]: {
      type: CONFIGURABLE_FIELD_DATA_TYPES;
      validations: CONFIGURABLE_FIELD_CHECKBOX_TYPES[];
      name: string;
    };
  };
} = {
  [REFERENCE_TYPES.COUNTRIES]: {
    name: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED],
      name: 'name',
    },
    code: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'code',
    },
  },
  [REFERENCE_TYPES.STATES]: {
    name: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED],
      name: 'name',
    },
    code: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'code',
    },
    country: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'country',
    },
  },
  [REFERENCE_TYPES.DISTRICTS]: {
    name: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED],
      name: 'name',
    },
    code: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'code',
    },
    state: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'state',
    },
  },
  [REFERENCE_TYPES.CITIES]: {
    name: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED],
      name: 'name',
    },
    code: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'code',
    },
    district: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'district',
    },
    postal_code: {
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      validations: [
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      ],
      name: 'postal_code',
    },
  },
};
