import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { CommonResponseDTO, IPersonalization } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { ColorSchemeService } from '../../../modules/setup/appearance/services/color-scheme.service';

@Injectable({ providedIn: 'root' })
export class PersonalizationService {
  public readonly dataStore = new BehaviorSubject<IPersonalization | undefined>(
    undefined
  );

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    colorSchemeService: ColorSchemeService
  ) {
    this.dataStore.subscribe((personalization) => {
      if (personalization) {
        localStorage.setItem('currentLang', personalization.language);
        translate.use(personalization.language);
        colorSchemeService.update(personalization.dark_mode ? 'dark' : 'light');
      }
    });
  }

  getPersonalizationData(): Observable<CommonResponseDTO<IPersonalization>> {
    const url = generateURL({ endpoint: ENDPOINTS.PERSONALIZATION_GET });
    return this.http.get<CommonResponseDTO<IPersonalization>>(url).pipe(
      tap((res) => {
        this.dataStore.next(res.data);
      })
    );
  }

  updatePersonalizationData(
    data: Partial<IPersonalization>,
    isUpdateTheme?: boolean
  ): Observable<CommonResponseDTO<IPersonalization>> {
    const url = generateURL({ endpoint: ENDPOINTS.PERSONALIZATION_UPDATE });
    return this.http.patch<CommonResponseDTO<IPersonalization>>(url, data).pipe(
      tap((res) => {
        if (isUpdateTheme) {
          this.dataStore.next(res.data);
        } else {
          localStorage.setItem('currentLang', res.data.language);
          this.translate.use(res.data.language);
        }
      })
    );
  }
}
