import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IExternalSystem,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ExternalSystemsService {
  private readonly _dataStore = new BehaviorSubject<IExternalSystem[]>([]);
  dataStore = this._dataStore.asObservable();

  constructor(private http: HttpClient) {}

  getExternalSystemsData(): Observable<CommonResponseDTO<IExternalSystem[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_GET_ALL,
    });
    return this.http.get<CommonResponseDTO<IExternalSystem[]>>(url).pipe(
      tap((res) => {
        this._dataStore.next(res.data);
      })
    );
  }

  getConnectedExternalReferenceCategories(
    systemId?: string
  ): Observable<CommonResponseDTO<IReferenceCategoryResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL,
      params: { id: systemId?.toString() },
    });

    return this.http.get<CommonResponseDTO<IReferenceCategoryResponse[]>>(url);
  }

  deleteExternalCategory(
    categoryId: string
  ): Observable<CommonResponseDTO<IReferenceCategoryResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE,
      params: { id: categoryId.toString() },
    });

    return this.http.delete<CommonResponseDTO<IReferenceCategoryResponse>>(url);
  }

  updateExternalSystemsData(
    data: IExternalSystem
  ): Observable<CommonResponseDTO<IExternalSystem>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE,
      params: { id: data._id?.toString() },
    });
    return this.http.patch<CommonResponseDTO<IExternalSystem>>(url, data).pipe(
      tap((res) => {
        const currentData = this._dataStore.value;
        const currentDataIndex = currentData.findIndex(
          (externalSystem) => externalSystem._id === data._id
        );

        if (currentDataIndex) {
          currentData[currentDataIndex] = res.data;
        } else {
          currentData.push(res.data);
        }

        this._dataStore.next(currentData);
      })
    );
  }
}
