import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { CISConfig } from '@el-chip-input-search';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

import { AUTHORIZER_TYPES } from '@shared/constants';
import { IConnectedAppResponse, IIdentityResponse } from '@shared/interfaces';

import { MAX_QUICK_ACTIONS } from '../../constants';
import {
  AuthService,
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../modules/auth/services';
import { ConnectionsService } from '../../modules/connections/services/connections.service';
import { ChooseALanguageBottomSheetComponent } from '../../modules/core/components/choose-a-language-bottom-sheet/choose-a-language-bottom-sheet.component';
import { AddToCollectionsService } from '../../modules/identities/services/add-to-collections.service';
import {
  GeneralSetupResponseDTO,
  SystemDataService,
} from '../../modules/setup/general/services/system-data.service';
import { IamConfigService } from '../../modules/setup/iam-config/services/iam-config.service';
import {
  CONFIRMATION_TYPES,
  GetUserConfirmationService,
  IConfirmationPopup,
} from '../../modules/shared/services/get-user-confirmation/service';
import { AfterLoginService } from '../../services/after-login.service';
import { FilesService } from '../../services/files.service';
import { FlowControlService } from '../../services/flow-control.service';
import { LoadingOverlayService } from '../../services/loading-overlay.service';
import { QuickActionService } from '../../services/quick-action.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';
import { PersonalizationService } from '../personalization/services/personalization.service';
import { PostItNotesService } from '../post-it-notes/services/post-it-notes.service';
import { AddQuickActionComponent } from '../quick-actions/popups/add-quick-action/add-quick-action.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  config: CISConfig = {};

  searchBarEnabled = false;
  goBackEnabled = false;
  resetPasswordDisabled = false;
  resetPasswordDisabledByAuthType = false;
  languageDisable = false;
  isFullScreen = false;
  isSidenavOpen = true;
  iconOnlyMode = false;
  isLaunchPad = false;
  quickActionsCount = 8;
  isInitialLoading = true;
  fullScreenLoading = false;
  pageContentLoading = false;

  title: string;
  screenWidth: number | undefined;

  imageUrl = '';

  userInformation: UserInfoResponseDTO;
  impersonatingIdentity: IIdentityResponse | undefined;
  private subscriptions = new Subscription();

  quickActionsIconList: { icon: string }[] = [];
  maxQuickActions = MAX_QUICK_ACTIONS;
  shortcuts: ShortcutInput[] = [];
  systemInformation: GeneralSetupResponseDTO;
  selectedApp: IConnectedAppResponse;

  constructor(
    public router: Router,
    private bottomSheet: MatBottomSheet,
    private systemDataService: SystemDataService,
    private personalizationService: PersonalizationService,
    private iamConfigService: IamConfigService,
    private loggedUserService: LoggedUserService,
    private flowControlService: FlowControlService,
    private authService: AuthService,
    private afterLoginService: AfterLoginService,
    private quickActionService: QuickActionService,
    private translate: TranslateService,
    private postItNotesService: PostItNotesService,
    private getUserConfirmation: GetUserConfirmationService,
    private dialog: MatDialog,
    private socket: Socket,
    private localStorage: LocalStorage,
    private filesService: FilesService,
    private snackBar: SnackbarService,
    private addToCollectionService: AddToCollectionsService,
    private connectionsService: ConnectionsService,
    private loadingOverlayService: LoadingOverlayService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.handleNavigation();
    this.handleResponsiveness();
    this.localStorage
      .getItem('impersonating_identity')
      .subscribe((data: IIdentityResponse) => {
        if (data) {
          this.impersonatingIdentity = data;
        }
      });
  }

  handleResponsiveness(): void {
    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 840) {
      this.isSidenavOpen = true;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  private initializeProfilePicture(): void {
    this.subscriptions.add(
      this.filesService.loggedUserProfilePicture.subscribe((change) => {
        this.imageUrl = change;
      })
    );
  }

  private initializeLoggedUserData(): void {
    this.loggedUserService.dataStore.subscribe(async (data) => {
      if (data) {
        this.userInformation = data;
        if (this.userInformation._id) {
          this.connectSocket(this.userInformation?._id);
        }

        if (this.userInformation.authorizer === AUTHORIZER_TYPES.AZURE_AD) {
          this.resetPasswordDisabledByAuthType = true;
        }

        this.localStorage
          .getItem('userInformation')
          .subscribe((data: UserInfoResponseDTO) => {
            if (data) {
              this.userInformation = {
                ...this.userInformation,
                first_name: data.first_name,
                last_name: data.last_name,
              };
            }
          });
      }
    });

    this.loggedUserService.identityImpersonatingStore?.subscribe((data) => {
      if (data) {
        this.localStorage
          .setItem('userInformation', this.userInformation)
          .subscribe();
        this.localStorage
          .setItem('impersonating_identity', this.impersonatingIdentity)
          .subscribe();
        this.impersonateLogin(data);
      }
    });
  }

  private impersonateLogin(user: IIdentityResponse = undefined) {
    if (user) {
      this.authService
        .impersonateLogin({
          username: user.username,
          impersonator: this.userInformation._id,
        })
        .subscribe({
          next: () => {
            this.impersonatingIdentity = user;
            this.router.navigate(['/app/launchpad']);
            this.snackBar.success(SUCCESS_TYPES.IMPERSONATE);
          },
          error: () => {
            this.snackBar.error(
              this.translate.instant('identities.impersonate.not-activate')
            );
          },
        });
    }
  }

  private initializeSystemData(): void {
    this.subscriptions.add(
      this.systemDataService.dataStore.subscribe((data) => {
        this.systemInformation = data;
      })
    );
  }

  private initializePersonalizationData(): void {
    this.subscriptions.add(
      this.personalizationService.dataStore.subscribe({
        next: (data) => {
          if (data) {
            if (data?.mini_side_menu) {
              this.iconOnlyMode = true;
              this.isSidenavOpen = false;
            } else {
              this.iconOnlyMode = false;
              this.isSidenavOpen = true;
            }

            window.dispatchEvent(new Event('resize'));
          } else {
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 1000);
          }
        },
        error: () => {
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 1000);
        },
      })
    );
  }

  private initializeSecurityData(): void {
    this.subscriptions.add(
      this.iamConfigService.dataStore.subscribe((data) => {
        this.resetPasswordDisabled =
          data?.user_management?.self_password_reset || false;
      })
    );
  }

  private initializeLanguageData(): void {
    const languageCount = localStorage.getItem('languageCount');

    if (parseInt(languageCount, 10) === 1) {
      this.languageDisable = true;
    }
  }

  private initializeCisConfigurationValues() {
    // Sets values for this.config from en.json
    // The config object is then used in the CIS in places like button texts, tooltips, placeholders, etc.
    // If certain values or no values are passed to config, el-chip-input-search component sets it's default values
    this.translate
      .get([
        'pages.dashboard.cis-config.title-prefix',
        'pages.dashboard.cis-config.search-button-text',
        'pages.dashboard.cis-config.search-button-tooltip',
        'pages.dashboard.cis-config.clear-button-tooltip',
        'pages.dashboard.cis-config.expand-tooltip',
        'pages.dashboard.cis-config.collapse-tooltip',
        'pages.dashboard.cis-config.section-expand-tooltip',
        'pages.dashboard.cis-config.section-collapse-tooltip',
        'pages.dashboard.cis-config.chip-close-tooltip',
        'pages.dashboard.cis-config.lock-tooltip',
        'pages.dashboard.cis-config.search-button-color',
        'pages.dashboard.cis-config.all-options-text',
        'pages.dashboard.cis-config.date-range-label-suffix',
      ])
      .subscribe((translations) => {
        this.config = {
          titlePrefix: translations['pages.dashboard.cis-config.title-prefix'],
          searchButtonText:
            translations['pages.dashboard.cis-config.search-button-text'],
          searchButtonTooltip:
            translations['pages.dashboard.cis-config.search-button-tooltip'],
          clearButtonTooltip:
            translations['pages.dashboard.cis-config.clear-button-tooltip'],
          expandTooltip:
            translations['pages.dashboard.cis-config.expand-tooltip'],
          collapseTooltip:
            translations['pages.dashboard.cis-config.collapse-tooltip'],
          sectionExpandTooltip:
            translations['pages.dashboard.cis-config.section-expand-tooltip'],
          sectionCollapseTooltip:
            translations['pages.dashboard.cis-config.section-collapse-tooltip'],
          chipCloseTooltip:
            translations['pages.dashboard.cis-config.chip-close-tooltip'],
          lockTooltip: translations['pages.dashboard.cis-config.lock-tooltip'],
          searchButtonColor:
            translations['pages.dashboard.cis-config.search-button-color'],
          allOptionsText:
            translations['pages.dashboard.cis-config.all-options-text'],
          dateRangeLabelSuffix:
            translations['pages.dashboard.cis-config.date-range-label-suffix'],
        };
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  disconnectSocket(): void {
    this.socket.disconnect();
  }

  connectSocket(identity_id: string): void {
    this.socket.ioSocket.io.opts.query = { identity_id };
    this.socket.connect();
  }

  isItemSelected(event: boolean, sidenav: any) {
    if (this.screenWidth < 840 && event) {
      sidenav?.toggle();
    }
  }

  onToggle(isSidenavOpen: boolean, iconOnlyMode: boolean): void {
    this.isSidenavOpen = isSidenavOpen;
    this.iconOnlyMode = iconOnlyMode;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
  }

  handleNavigation(): void {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loadingOverlayService.showPageContentLoading();
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loadingOverlayService.hidePageContentLoading();
            break;
          }
          default: {
            break;
          }
        }
      })
    );
  }

  ngOnInit() {
    this.afterLoginService.do();
    this.initializeProfilePicture();
    this.initializeLoggedUserData();
    this.initializeSystemData();
    this.initializeSecurityData();
    this.initializeLanguageData();
    this.initializeFlowControlService();
    this.initializeCisConfigurationValues();
    this.initializePersonalizationData();
    this.getQuickActionsList();

    this.loadingOverlayService.fullScreenLoading.subscribe((isLoading) => {
      this.fullScreenLoading = isLoading;
    });
    this.loadingOverlayService.pageContentLoading.subscribe((isLoading) => {
      this.pageContentLoading = isLoading;
    });

    if (this.router.url === 'app/launchpad') {
      this.isLaunchPad = true;
    } else {
      this.isLaunchPad = false;
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['alt + n'],
      label: 'Open in New Tab',
      description: 'Alt + N',
      command: () => this.openInNewTab(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['alt + f'],
      label: 'Toggle Full Screen',
      description: 'Alt + F',
      command: () =>
        !this.isFullScreen ? this.openFullscreen() : this.closeFullscreen(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['alt + s'],
      label: 'Toggle Side Menu',
      description: 'Alt + S',
      command: () =>
        this.isSidenavOpen
          ? this.onToggle(false, true)
          : this.onToggle(true, false),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['alt + l'],
      label: 'Log Out',
      description: 'Alt + L',
      command: () => this.logout(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['alt + x'],
      label: 'Pin as Quick Action',
      description: 'Alt + X',
      command: () => this.onCreateQuickAction(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['alt + p'],
      label: 'Profile',
      description: 'Alt + P',
      command: () => this.goToProfile(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['alt + a'],
      label: 'Add a Post-it Note',
      description: 'Alt + A',
      command: () => this.postItNotesService.onShortcutTrigger(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + m'],
      label: 'Go to Modules module',
      description: 'Shift + M',
      command: () => this.goToModulesModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + a'],
      label: 'Go to Apps module',
      description: 'Shift + A',
      command: () => this.goToAppsModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + p'],
      label: 'Go to Permissions module',
      description: 'Shift + P',
      command: () => this.goToPermissionsModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + r'],
      label: 'Go to Roles module',
      description: 'Shift + R',
      command: () => this.goToRolesModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + i'],
      label: 'Go to Identities module',
      description: 'Shift + I',
      command: () => this.goToIdentitiesModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + c'],
      label: 'Go to Connections module',
      description: 'Shift + C',
      command: () => this.goToConnectionsModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + e'],
      label: 'Go to Messages module',
      description: 'Shift + E',
      command: () => this.goToMessagesModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + d'],
      label: 'Go to Drives module',
      description: 'Shift + D',
      command: () => this.goToDrivesModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + y'],
      label: 'Go to Trash module',
      description: 'Shift + Y',
      command: () => this.goToRecycleBinModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + n'],
      label: 'Go to Notices module',
      description: 'Shift + N',
      command: () => this.goToNoticesModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + k'],
      label: 'Go to Knowledgebase module',
      description: 'Shift + K',
      command: () => this.goToKnowledgebaseModule(),
      preventDefault: true,
    });

    this.shortcuts.push({
      key: ['shift + l'],
      label: 'Go to Logs module',
      description: 'Shift + L',
      command: () => this.goToLogsModule(),
      preventDefault: true,
    });
  }

  changeMenuItem(menuKey: boolean) {
    if (menuKey) {
      this.isLaunchPad = true;
    } else {
      this.isLaunchPad = false;
    }
  }

  removeImpersonate() {
    this.loggedUserService.impersonateIdentity(null);
    this.impersonatingIdentity = undefined;
    this.localStorage.getItem('impersonate_data').subscribe((_data: any) => {
      if (_data) {
        this.authService
          .refreshToken(_data.refresh_token, _data)
          .subscribe(() => {
            this.router.navigate(['/app/launchpad']);
            this.snackBar.success(SUCCESS_TYPES.IMPERSONATE_END);
          });
      }
    });
  }

  initializeFlowControlService = () => {
    this.subscriptions.add(
      this.flowControlService.dataStore.subscribe((change) => {
        this.title = change?.lastVisitedRoute?.data?.title;
        this.goBackEnabled = change?.lastVisitedRoute?.data?.goBackEnabled;
        this.searchBarEnabled =
          change?.lastVisitedRoute?.data?.searchBarEnabled;
      })
    );
  };

  onBackClicked() {
    window.history.back();
  }

  logout() {
    const data: Omit<IConfirmationPopup, 'type'> = {
      description: this.translate.instant(
        'core.keyboard-shortcuts.log-out-confirmation-popup'
      ),
      need_authentication: false,
      icon: 'logout',
      color: 'warn',
    };

    this.getUserConfirmation
      .confirm(CONFIRMATION_TYPES.CUSTOM, data)
      .then((result) => {
        if (result) {
          // TODO:@rukshan FIX the sometimes not reconnecting issue
          // FIXME:@rukshan shouldn't disconnectSocket be inside the authService.logout function?
          // so it will get executed from anywhere we execute logout?
          this.localStorage.clear().subscribe();
          this.loggedUserService.impersonateIdentity(null);
          this.disconnectSocket();
          this.authService.logout().then(() => {
            this.router.navigate(['/app/auth/login']);
          });
        }
      });
  }

  openBottomSheet(): void {
    this.bottomSheet.open(ChooseALanguageBottomSheetComponent);
  }

  // open full screen
  openFullscreen() {
    if (this.document.documentElement['requestFullscreen']) {
      this.document.documentElement['requestFullscreen']();
    }

    if (this.document.documentElement['mozRequestFullScreen']) {
      /* Firefox */
      this.document.documentElement['mozRequestFullScreen']();
    }

    if (this.document.documentElement['webkitRequestFullscreen']) {
      /* Chrome, Safari and Opera */
      this.document.documentElement['webkitRequestFullscreen']();
    }

    if (this.document.documentElement['msRequestFullscreen']) {
      /* IE/Edge */
      this.document.documentElement['msRequestFullscreen']();
    }
  }

  // close full screen
  closeFullscreen() {
    if (this.document['exitFullscreen']) {
      this.document['exitFullscreen']();
    }

    if (this.document['mozCancelFullScreen']) {
      /* Firefox */
      this.document['mozCancelFullScreen']();
    }

    if (this.document['webkitExitFullscreen']) {
      /* Chrome, Safari and Opera */
      this.document['webkitExitFullscreen']();
    }

    if (this.document['msExitFullscreen']) {
      /* IE/Edge */
      this.document['msExitFullscreen']();
    }
  }

  getQuickActionsList(): void {
    this.quickActionService.getAllQuickActions().subscribe();
    this.subscriptions.add(
      this.quickActionService.createdQuickActions.subscribe((res) => {
        this.quickActionsCount = res.length;
      })
    );
  }

  onCreateQuickAction() {
    const dataList = {
      pathURL: this.router.url,
    };
    this.dialog
      .open(AddQuickActionComponent, {
        data: dataList,
        maxWidth: '600px',
        width: '90%',
      })
      .afterClosed()
      .subscribe(() => {
        this.quickActionService.getAllQuickActions().subscribe();
      });
  }

  openInNewTab() {
    window.open(this.router.url, '_blank');
  }

  goToProfile() {
    this.router.navigateByUrl('/app/profile');
  }

  goToModulesModule() {
    this.router.navigateByUrl('/app/modules?is_search=true');
  }

  goToAppsModule() {
    this.router.navigateByUrl('/app/apps?is_search=true');
  }

  goToPermissionsModule() {
    this.router.navigateByUrl('/app/authorization/permissions?is_search=true');
  }

  goToRolesModule() {
    this.router.navigateByUrl('/app/authorization/roles?is_search=true');
  }

  goToIntegrationsModule() {
    this.router.navigateByUrl('/app/integrations?is_search=true');
  }

  goToIdentitiesModule() {
    this.router.navigateByUrl('/app/identities?is_search=true');
  }

  goToConnectionsModule() {
    this.router.navigateByUrl('/app/connections?is_search=true');
  }

  goToMessagesModule() {
    this.router.navigateByUrl('/app/messages?is_search=true');
  }

  goToDrivesModule() {
    this.router.navigateByUrl('/app/storage/drives?is_search=true');
  }

  goToRecycleBinModule() {
    this.router.navigateByUrl('/app/storage/trash?is_search=true');
  }

  goToNoticesModule() {
    this.router.navigateByUrl('/app/notices?is_search=true');
  }

  goToKnowledgebaseModule() {
    this.router.navigateByUrl('/app/knowledgebase?is_search=true');
  }

  goToLogsModule() {
    this.router.navigateByUrl('/app/logs?is_search=true');
  }

  goToSetupGeneralModule() {
    this.router.navigateByUrl('/app/setup/general');
  }

  goToSetupIdentityAndAccessModule() {
    this.router.navigateByUrl('/app/setup/identity-and-access');
  }

  goToSetupStorageModule() {
    this.router.navigateByUrl('/app/setup/storage');
  }

  goToSetupAppearanceModule() {
    this.router.navigateByUrl('/app/setup/appearance');
  }

  goToSetupLanguagesModule() {
    this.router.navigateByUrl('/app/setup/languages');
  }

  goToSetupCurrenciesModule() {
    this.router.navigateByUrl('/app/setup/currencies');
  }

  goToSetupNotificationsModule() {
    this.router.navigateByUrl('/app/setup/notifications');
  }

  goToSetupMessagesModule() {
    this.router.navigateByUrl('/app/setup/messages');
  }

  goToSetupSecurityModule() {
    this.router.navigateByUrl('/app/setup/security');
  }

  goToSetupReleasesModule() {
    this.router.navigateByUrl('/app/setup/releases');
  }

  goToSetupSupportModule() {
    this.router.navigateByUrl('/app/setup/support');
  }

  goToSetupMaintenanceModule() {
    this.router.navigateByUrl('/app/setup/maintenance');
  }

  goToSetupDebuggingModule() {
    this.router.navigateByUrl('/app/setup/debugging');
  }

  goToSetupProgressiveWebAppModule() {
    this.router.navigateByUrl('/app/setup/progressive-web-app');
  }

  goToSetupBackupsModule() {
    this.router.navigateByUrl('/app/setup/backups');
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  toggleFullscreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  getInputWidth(length: number, label: HTMLElement): number {
    const labelWidth = label.offsetWidth;
    return Math.max(labelWidth, length * 7.5 || 0);
  }
}
