import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { IConnectedAppResponse, IIdentityResponse } from '@shared/interfaces';

import { SnackbarService } from '../../../services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AddToCollectionsService {
  public collection = new BehaviorSubject<IIdentityResponse[]>([]);

  public selectedAppSub = new BehaviorSubject<IConnectedAppResponse>(null);

  constructor(
    private snackBar: SnackbarService,
    private translate: TranslateService
  ) {}

  public addToCollection(data: IIdentityResponse) {
    const currentCollectionHasId = this.collection.value.find(
      (o) => o._id === data._id
    );

    if (currentCollectionHasId) {
      return this.snackBar.info(
        this.translate.instant(
          'identities.search.already-in-collection-snackbar'
        )
      );
    }
    const updatedCollection = [...this.collection.value, data];
    this.collection.next(updatedCollection);
  }

  public setAllToCollection(data: IIdentityResponse[]) {
    let updatedCollection = [...this.collection.value];
    data.forEach((dataElem) => {
      const currentCollectionHasId = updatedCollection.some((dataItem) => {
        return dataItem?._id === dataElem?._id ? true : false;
      });
      if (!currentCollectionHasId) {
        updatedCollection = [...updatedCollection, dataElem];
      }
    });
    this.collection.next(updatedCollection);
  }

  public removeFromCollection(id: string) {
    const identityArr: any[] = this.collection.getValue();
    const remainingArr = identityArr.filter((identity) => identity._id !== id);
    this.collection.next(remainingArr);
  }

  public clearCollection() {
    this.collection.next([]);
  }

  public setSingleIdentity(data: IIdentityResponse) {
    this.collection.next([data]);
  }

  public setMultipleIdentities(data: IIdentityResponse[]) {
    this.collection.next(data);
  }

  public setApp(value: IConnectedAppResponse | null) {
    this.selectedAppSub.next(value);
  }
}
