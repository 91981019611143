import { Injectable } from '@angular/core';
import { ELAutocompleteElement } from '@el-autocomplete';
import { BehaviorSubject } from 'rxjs';

import { REFERENCE_TYPES } from '@shared/constants';
import {
  ICityReference,
  ICountryReference,
  IDistrictReference,
  IReference,
  IStateReference,
} from '@shared/interfaces';

import { ReferenceService } from './reference.service';

@Injectable({ providedIn: 'root' })
export class AddressReferenceService {
  private countries = new BehaviorSubject<IReference<ICountryReference>[]>([]);
  private countryWiseStates = new BehaviorSubject<{
    [countryId: string]: IReference<IStateReference>[];
  }>({});
  private stateWiseDistricts = new BehaviorSubject<{
    [provinceId: string]: IReference<IDistrictReference>[];
  }>({});
  private districtWiseCities = new BehaviorSubject<{
    [districtId: string]: IReference<ICityReference>[];
  }>({});

  constructor(private referenceService: ReferenceService) {}

  async getCountriesToAddressPopup(): Promise<ELAutocompleteElement[]> {
    const currentCountries = this.countries.value;

    const referencesToDropdowns = async (
      references: IReference<ICountryReference>[]
    ): Promise<ELAutocompleteElement[]> => {
      if (!references.length) return [];

      const elements: ELAutocompleteElement[] = await Promise.all(
        references.map(async (reference) => {
          return {
            value: reference._id.toString(),
            displayValue: reference.name,
            originalData: reference,
          };
        })
      );

      return elements.filter(Boolean);
    };

    if (currentCountries.length > 0)
      return referencesToDropdowns(currentCountries);

    const allReferencesUnderCategory =
      await this.referenceService.getReferencesForAddressPopup(
        REFERENCE_TYPES.COUNTRIES
      );

    this.countries.next(allReferencesUnderCategory);

    return referencesToDropdowns(
      allReferencesUnderCategory as unknown as IReference<ICountryReference>[]
    );
  }

  async getStatesToAddressPopup(
    countryId: string
  ): Promise<ELAutocompleteElement[]> {
    const currentStates = this.countryWiseStates.value;

    const referencesToDropdowns = async (
      references: IReference<IStateReference>[]
    ): Promise<ELAutocompleteElement[]> => {
      if (!references.length) return [];

      const elements: ELAutocompleteElement[] = await Promise.all(
        references.map(async (reference) => {
          return {
            value: reference._id.toString(),
            displayValue: reference.name,
            originalData: reference,
          };
        })
      );

      return elements.filter(Boolean);
    };

    if (currentStates[countryId]?.length > 0)
      return referencesToDropdowns(currentStates[countryId]);

    const allReferencesUnderCategory =
      await this.referenceService.getReferencesForAddressPopup(
        REFERENCE_TYPES.STATES,
        countryId
      );

    currentStates[countryId] =
      allReferencesUnderCategory as IReference<IStateReference>[];
    this.countryWiseStates.next(currentStates);

    return referencesToDropdowns(
      allReferencesUnderCategory as unknown as IReference<IStateReference>[]
    );
  }

  async getDistrictsToAddressPopup(
    stateId: string
  ): Promise<ELAutocompleteElement[]> {
    const currentDistricts = this.stateWiseDistricts.value;

    const referencesToDropdowns = async (
      references: IReference<IDistrictReference>[]
    ): Promise<ELAutocompleteElement[]> => {
      if (!references.length) return [];

      const elements: ELAutocompleteElement[] = await Promise.all(
        references.map(async (reference) => {
          return {
            value: reference._id.toString(),
            displayValue: reference.name,
            originalData: reference,
          };
        })
      );

      return elements.filter(Boolean);
    };

    if (currentDistricts[stateId]?.length > 0)
      return referencesToDropdowns(currentDistricts[stateId]);

    const allReferencesUnderCategory =
      await this.referenceService.getReferencesForAddressPopup(
        REFERENCE_TYPES.DISTRICTS,
        stateId
      );

    currentDistricts[stateId] =
      allReferencesUnderCategory as IReference<IDistrictReference>[];
    this.stateWiseDistricts.next(currentDistricts);

    return referencesToDropdowns(
      allReferencesUnderCategory as unknown as IReference<IDistrictReference>[]
    );
  }

  async getCitiesToAddressPopup(
    districtId: string
  ): Promise<ELAutocompleteElement[]> {
    const currentCities = this.districtWiseCities.value;

    const referencesToDropdowns = async (
      references: IReference<ICityReference>[]
    ): Promise<ELAutocompleteElement[]> => {
      if (!references.length) return [];

      const elements: ELAutocompleteElement[] = await Promise.all(
        references.map(async (reference) => {
          return {
            value: reference._id.toString(),
            displayValue: reference.name,
            originalData: reference,
          };
        })
      );

      return elements.filter(Boolean);
    };

    if (currentCities[districtId]?.length > 0)
      return referencesToDropdowns(currentCities[districtId]);

    const allReferencesUnderCategory =
      await this.referenceService.getReferencesForAddressPopup(
        REFERENCE_TYPES.CITIES,
        districtId
      );

    currentCities[districtId] =
      allReferencesUnderCategory as IReference<ICityReference>[];
    this.districtWiseCities.next(currentCities);

    return referencesToDropdowns(
      allReferencesUnderCategory as unknown as IReference<ICityReference>[]
    );
  }
}
