import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IRoleRequest,
  IRoleResponse,
} from '@shared/interfaces';

type RoleResponse = CommonResponseDTO<IRoleResponse>;

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  public $isRoleCreated = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getSingleRole(roleId: string): Observable<RoleResponse> {
    return this.http.get<RoleResponse>(`${URLS.ROLES}/${roleId}`);
  }

  getSingleRoleByParams(
    params = new HttpParams()
  ): Observable<CommonResponseDTO<IRoleResponse>> {
    return this.http.get<CommonResponseDTO<IRoleResponse>>(
      `${URLS.ROLES}/single-role`,
      {
        params,
      }
    );
  }

  getAllRoles(
    params = new HttpParams()
  ): Observable<CommonResponseDTO<IRoleResponse[]>> {
    return this.http.get<CommonResponseDTO<IRoleResponse[]>>(URLS.ROLES, {
      params,
    });
  }

  createNewRole(data: IRoleRequest): Observable<RoleResponse> {
    return this.http.post<RoleResponse>(URLS.ROLES, data);
  }

  updateRole(data: IRoleRequest, id: string): Observable<RoleResponse> {
    const url = `${URLS.ROLES}/${id}`;
    return this.http.put<RoleResponse>(url, data);
  }

  deleteRole(id: string): Observable<RoleResponse> {
    const url = `${URLS.ROLES}/${id}`;
    return this.http.delete<RoleResponse>(url);
  }

  restoreRole(id: string): Observable<RoleResponse> {
    const url = `${URLS.ROLES}/${id}/restore`;
    return this.http.patch<RoleResponse>(url, undefined);
  }

  changeDefaultRole(id: string): Observable<RoleResponse> {
    const url = `${URLS.ROLES}/${id}/default`;
    return this.http.patch<RoleResponse>(url, undefined);
  }

  public roleCreationButtonClicked(value: boolean) {
    this.$isRoleCreated.next(value);
  }
}
