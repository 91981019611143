export enum MODULES {
  IDENTITIES = 'IDENTITIES',
  APPS = 'APPS',
  CONNECTIONS = 'CONNECTIONS',
  MODULES = 'MODULES',
  NOTICES = 'NOTICES',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  MESSAGES = 'MESSAGES',
  LOGS = 'LOGS',
  AUTHORIZATION_PERMISSIONS = 'AUTHORIZATION_PERMISSIONS',
  AUTHORIZATION_ROLES = 'AUTHORIZATION_ROLES',
  AUTHORIZATION_PRIVILEGES = 'AUTHORIZATION_PRIVILEGES',
  STORAGE_DRIVE = 'STORAGE_DRIVE',
  STORAGE_RECYCLE_BIN = 'STORAGE_TRASH',
  REFERENCES = 'REFERENCES',
  INTEGRATIONS = 'INTEGRATIONS',
  SETUP = 'SETUP',
}

export enum MODULE_STATUSES {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  HIDDEN = 'hidden',
  DISABLE = 'disable',
}
