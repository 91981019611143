import { Injectable } from '@angular/core';

import { ModulesService } from '../modules/modules/services/modules.service';
import { PersonalizationService } from '../pages/personalization/services/personalization.service';

@Injectable({ providedIn: 'root' })
export class AfterLoginService {
  constructor(
    private personalizationService: PersonalizationService,
    private modulesService: ModulesService
  ) {}

  do() {
    this.personalizationService.getPersonalizationData().subscribe();
    this.modulesService.getAllModules().subscribe();
  }
}
