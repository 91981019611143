import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  firstValueFrom,
  forkJoin,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';

import {
  CONFIGURABLE_FIELD_DATA_TYPES,
  MODULES,
  SALUTATIONS,
  SPECIAL_PERMISSION_ACTIONS,
  USER_TYPES,
  USERNAME_REGEX,
} from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IConfigurableFieldValue,
  IConfigurableFieldValueResponse,
  IContactNumber,
  IIdentity,
  IIdentityResponse,
  IRole,
  IUploadFileToDMS,
  MultiFieldData,
} from '@shared/interfaces';
import { emailValidator } from '@shared/utils';

import {
  IConnectionField,
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../modules/auth/services';
import {
  DisplayConfigurableFieldService,
  IFieldValidationResults,
} from '../../modules/configurable-fields/services';
import { IDisplayConfigurableFieldElement } from '../../modules/configurable-fields/types';
import { noWhitespaceValidator } from '../../modules/core/helpers/form-field-white-space-validator';
import { IConnectToAppRequest } from '../../modules/identities/pages/connect-to-app/connect-to-app.component';
import { ModulesService } from '../../modules/modules/services/modules.service';
import { RolesService } from '../../modules/permissions/services/roles.service';
import {
  GeneralSetupResponseDTO,
  SystemDataService,
} from '../../modules/setup/general/services/system-data.service';
import {
  Config,
  IamConfigService,
} from '../../modules/setup/iam-config/services/iam-config.service';
import { DateTimeFormatService } from '../../services/date-time-format.service';
import { FilesService } from '../../services/files.service';
import { FlowControlService } from '../../services/flow-control.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';
import { ThirdPartyApiService } from '../../services/third-party-api.service';

import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';

export interface AdditionalField {
  metadata: IConfigurableFieldConfigResponse;
  form: UntypedFormControl;

  index?: number;
}

export interface IExtendedField extends IConfigurableFieldConfigResponse {
  is_external: boolean;
}

interface LoginActivity {
  timestamp?: string;
  country?: string;
  city?: string;
  country_code?: string;
}

export enum ROLE_TYPES {
  CUSTOM = 'Custom',
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy /* , OnChanges */ {
  @Input() data: IConnectToAppRequest;
  salutations = Object.values(SALUTATIONS);
  CONFIGURABLE_FIELD_DATA_TYPES = CONFIGURABLE_FIELD_DATA_TYPES;

  private onDestroy$ = new Subject<void>();
  systemInformation: GeneralSetupResponseDTO;

  hasSelfResetPassword = false;

  isLoading = false;

  IAMConfig: Config;
  appId: string;

  imageUrl: string;
  identity: IIdentity | IIdentityResponse | UserInfoResponseDTO;
  lastLoginActivity: LoginActivity = {};

  index: number;
  systemIndex: number;
  systemDataIndex: number;

  subscriptions = new Subscription();

  additionalFields: AdditionalField[] = [];

  internalFields: IConfigurableFieldValue[] = [];
  externalFields: IConnectionField[] = [];

  loggedUserData: UserInfoResponseDTO;

  hasEditPermission = true;

  phoneNumber: MultiFieldData = {
    type: 'PHONE',
    value: [],
    fieldConfig: {
      name: 'Phone Number',
      type: CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER,
    },
  };

  profileFormGroup = new UntypedFormGroup({
    salutation: new UntypedFormControl('Mr.', Validators.required),
    first_name: new UntypedFormControl(null, [
      Validators.required,
      noWhitespaceValidator,
    ]),
    last_name: new UntypedFormControl(null),
    email: new UntypedFormControl(null, emailValidator()),
    username: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern(USERNAME_REGEX),
    ]),
  });

  internal_fields_display: IDisplayConfigurableFieldElement[] = [];
  external_fields_display: IDisplayConfigurableFieldElement[] = [];

  internalFieldsDataValidation: IFieldValidationResults = {
    isValid: false,
  };
  externalFieldsDataValidation: IFieldValidationResults = {
    isValid: false,
  };

  isInternalFieldsPristine = false;
  isExternalFieldsChanged = false;

  isInternalFieldsInValid = false;
  isExternalFieldsInValid = false;

  loggedInRole: IRole;

  constructor(
    private loggedUserService: LoggedUserService,
    private snackBar: SnackbarService,
    private iamConfigService: IamConfigService,
    private dialog: MatDialog,
    flowControlService: FlowControlService,
    router: Router,
    route: ActivatedRoute,
    private modulesService: ModulesService,
    private systemDataService: SystemDataService,
    private thirdPartyApiService: ThirdPartyApiService,
    private dateTimeFormatService: DateTimeFormatService,
    private filesService: FilesService,
    private displayConfigurableFieldService: DisplayConfigurableFieldService,
    private translate: TranslateService,
    private rolesService: RolesService
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.initializeIamConfig();
    this.initializeSystemData();
    this.initializeUserInfo();
    await this.initializeRoleInfo();
    this.initializeUserPermissions();
  }

  async initializeRoleInfo() {
    if (this.loggedUserData?.connection?.role_name === ROLE_TYPES.CUSTOM) {
      return;
    } else {
      const role = this.loggedUserData?.connection?.role_name;
      const params = new HttpParams().append('role', role);

      try {
        const res = await firstValueFrom(this.rolesService.getAllRoles(params));
        this.loggedInRole = res.data[0];
      } catch (error) {
        console.error('Failed to get roles:', error);
      }
    }
  }

  async initializeUserPermissions() {
    if (this.loggedUserData.user_type === USER_TYPES.EL_ADMIN) return;

    const loggedConnection = this.loggedUserData?.connection;
    let index;

    if (loggedConnection.role_name === ROLE_TYPES.CUSTOM) {
      index = loggedConnection.permissions.findIndex(
        (permission) => permission === SPECIAL_PERMISSION_ACTIONS.EDIT_PROFILE
      );
    } else {
      index = this.loggedInRole.permissions.findIndex(
        (permission) => permission === SPECIAL_PERMISSION_ACTIONS.EDIT_PROFILE
      );
    }

    this.hasEditPermission = index !== -1;

    if (this.hasEditPermission) {
      this.profileFormGroup.enable();
    } else {
      this.profileFormGroup.disable();
    }
  }

  initializeIamConfig(): void {
    const iamConfigSub = this.iamConfigService.dataStore.subscribe((change) => {
      this.IAMConfig = change;
      this.hasSelfResetPassword = change?.user_management?.self_password_reset;
    });
    this.subscriptions.add(iamConfigSub);
  }

  initializeUserInfo(): void {
    this.subscriptions.add(
      this.loggedUserService.dataStore.subscribe(async (change) => {
        if (change) {
          this.appId = change?.connection?.app?._id?.toString();
          this.profileFormGroup.patchValue({ ...change });
          this.loggedUserData = {
            ...change,
            all_connections: change?.all_connections?.filter(
              (connection) => !connection?.app?.self
            ),
          };
          this.internalFields = change.internal_fields;
          if (
            change.connection &&
            change?.connection?.data_fields &&
            change?.connection?.data_fields.length > 0
          ) {
            this.externalFields = change.connection.data_fields;
          }
          this.identity = change;
          if (this.loggedUserData.last_login) {
            this.lastLoginActivity.timestamp =
              this.dateTimeFormatService.formatDateTime(
                this.loggedUserData.last_login
              );
          }

          //populate phone numbers
          this.phoneNumber = {
            type: 'PHONE',
            value: change.phone_number,
            fieldConfig: {
              ...this.phoneNumber.fieldConfig,
            },
          };

          this.fetchGeoLocation(
            this.loggedUserData.ip_address
              ? this.loggedUserData.ip_address
              : 'localhost'
          );

          this.getAdditionalFields();
        }
      })
    );
  }

  initializeSystemData(): void {
    const systemDataSub = this.systemDataService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.systemInformation = data;
      });

    this.subscriptions.add(systemDataSub);
  }

  onOtherValueEmitted(data: MultiFieldData) {
    this.phoneNumber = data;
    this.profileFormGroup.markAsDirty();
  }

  private isAllFormsValid(): boolean {
    return this.internalFieldsDataValidation.isValid;
  }
  private isAllExternalFormsValid(): boolean {
    return this.externalFieldsDataValidation.isValid;
  }

  getAdditionalFields() {
    this.isLoading = true;

    forkJoin({
      basicInformationDataFields: this.modulesService.getModuleByKey(
        MODULES.IDENTITIES
      ),
      profilePicture: this.filesService.getProfilePictureOneTime(
        this.identity as IIdentity
      ),
    }).subscribe({
      next: async (res) => {
        // get profile picture
        this.loggedUserService.dataStore
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(async (data) => {
            if (data && this.identity) {
              const newImageUrl = res.profilePicture;
              this.imageUrl = newImageUrl;
            }
          });

        if (this.internalFields.length > 0) {
          // get additional fields
          const displayInternalFields =
            await this.displayConfigurableFieldService.generateFormFields(
              res.basicInformationDataFields.fields,
              'internal',
              this.onDestroy$,
              this.internalFields as any as IConfigurableFieldValueResponse[]
            );
          this.internal_fields_display = displayInternalFields.fields;

          //set internal fields for display
          this.internalFields.forEach((identity_fields) => {
            const foundField = this.internal_fields_display.find(
              (field) =>
                field.configuration?._id?.toString() ===
                identity_fields?.field_id?.toString()
            );
            if (foundField) {
              foundField.field_value.setValue(identity_fields.value);
              foundField.field_value.updateValueAndValidity();
            }
          });

          //internal fields validations
          this.internalFieldsDataValidation =
            await this.displayConfigurableFieldService.validateAndGetValueOfConfigurableFields(
              this.internal_fields_display
            );
          this.internal_fields_display.forEach((field) => {
            field.field_value.valueChanges
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(async () => {
                this.isInternalFieldsInValid = false;
                this.internalFieldsDataValidation =
                  await this.displayConfigurableFieldService.validateAndGetValueOfConfigurableFields(
                    this.internal_fields_display
                  );
                this.validateAdditionalFields();
                this.isInternalFieldsInValid =
                  !this.isAllFormsValid() || this.isInternalFieldsInValid;
              });
          });
          displayInternalFields.validation
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((validation) => {
              this.internalFieldsDataValidation = validation;
            });
        }
        //NOTE: check the app id and send it with the additional data, then update from the api with relevant app and app id
        //for client side
        if (
          !this.loggedUserData?.all_connections &&
          this.externalFields.length > 0
        ) {
          // get additional fields
          const displayExternalFields =
            await this.displayConfigurableFieldService.generateFormFields(
              res.basicInformationDataFields.fields,
              'internal',
              this.onDestroy$,
              this.externalFields as any as IConfigurableFieldValueResponse[]
            );

          this.external_fields_display = displayExternalFields.fields;

          //set external fields for display
          this.externalFields.forEach((identity_fields) => {
            const foundField = this.external_fields_display.find(
              (field) =>
                field.configuration?._id?.toString() ===
                identity_fields?.field_id?.toString()
            );
            if (foundField) {
              foundField.field_value.setValue(identity_fields.value);
              foundField.field_value.updateValueAndValidity();
            }
          });

          //internal fields validations
          this.externalFieldsDataValidation =
            await this.displayConfigurableFieldService.validateAndGetValueOfConfigurableFields(
              this.external_fields_display
            );
          this.external_fields_display.forEach((field) => {
            field.field_value.valueChanges
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(async () => {
                this.isExternalFieldsInValid = false;
                this.externalFieldsDataValidation =
                  await this.displayConfigurableFieldService.validateAndGetValueOfConfigurableFields(
                    this.external_fields_display
                  );
                this.validateAdditionalFields();
                this.isExternalFieldsInValid =
                  !this.isAllExternalFormsValid() ||
                  this.isExternalFieldsInValid;
              });
          });
          displayExternalFields.validation
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((validation) => {
              this.externalFieldsDataValidation = validation;
            });
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  fetchGeoLocation(ipAddress: string) {
    this.thirdPartyApiService.getLocationInfo(ipAddress).subscribe((res) => {
      this.lastLoginActivity.city = res.data?.city?.city?.names?.en;
      this.lastLoginActivity.country = res.data?.city?.country?.names?.en;
      this.lastLoginActivity.country_code = res.data?.city?.country?.isoCode;
    });
  }

  validateAdditionalFields(): void {
    if (this.internalFields.length > 0) {
      this.isInternalFieldsInValid = this.internal_fields_display.some(
        (field) => field.field_value.invalid
      );
      this.isInternalFieldsPristine = this.internal_fields_display.some(
        (field) => !field.field_value.pristine
      );
    } else if (this.externalFields.length > 0) {
      this.isExternalFieldsInValid = this.external_fields_display.some(
        (field) => field.field_value.invalid
      );
      this.isExternalFieldsChanged = this.external_fields_display.some(
        (field) => !field.field_value.pristine
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmitProfileChange(): void {
    this.isLoading = true;

    if (this.IAMConfig?.iam?.status === 'EXTERNAL') {
      const externalFieldData: IConfigurableFieldValue[] = [];
      this.external_fields_display.forEach((field) => {
        externalFieldData.push({
          field_id: field.configuration._id,
          value: field.field_value.value,
        });
      });
      const data = {
        ...this.profileFormGroup.value,
        external_fields: externalFieldData,
        phone_number: this.phoneNumber.value as IContactNumber[],
        appId: this.appId,
      };
      this.loggedUserService.updateUserProfile(data).subscribe({
        next: () => {
          this.isLoading = false;
          this.external_fields_display.forEach((field) =>
            field.field_value.markAsPristine()
          );
          this.isExternalFieldsChanged = false;
          this.profileFormGroup.markAsPristine();
          this.snackBar.success(
            SUCCESS_TYPES.UPDATED,
            this.translate.instant('pages.profile.Profile-updated')
          );
        },
        error: () => {
          this.isLoading = false;
        },
      });
    } else {
      const internalFieldData: IConfigurableFieldValue[] = [];
      this.internal_fields_display.forEach((field) => {
        internalFieldData.push({
          field_id: field.configuration._id,
          value: field.field_value.value,
        });
      });
      const data = {
        ...this.profileFormGroup.value,
        internal_fields: internalFieldData,
        phone_number: this.phoneNumber.value as IContactNumber[],
        appId: this.appId,
      };
      this.loggedUserService.updateUserProfile(data).subscribe({
        next: () => {
          this.isLoading = false;
          this.internal_fields_display.forEach((field) =>
            field.field_value.markAsPristine()
          );
          this.isInternalFieldsPristine = false;
          this.profileFormGroup.markAsPristine();
          this.snackBar.success(
            SUCCESS_TYPES.UPDATED,
            this.translate.instant('pages.profile.Profile-updated')
          );
        },
        error: () => {
          this.isLoading = false;
        },
      });
    }
  }

  openFile(): void {
    if (!this.hasEditPermission) {
      this.snackBar.error(
        this.translate.instant('pages.profile.permission-error')
      );
      return;
    }

    const dialogRef = this.dialog.open(ImageCropperDialogComponent);
    dialogRef.afterClosed().subscribe((file: File) => {
      if (file) {
        this.isLoading = true;

        const uploadData: IUploadFileToDMS<File> = {
          file,
          generate_name: true,
          is_system: true,
          is_public: true,
          path: 'Profile Pictures',
          tags: ['profile', 'pictures', 'profile-pictures', 'dp'],
        };
        this.loggedUserService.uploadProfileDataToDMS(uploadData).subscribe({
          next: (res) => {
            this.loggedUserData.profile_image = res.data._id.toString();
            this.loggedUserService
              .updateUserProfile(this.loggedUserData)
              .subscribe({
                next: async (data) => {
                  if (data.success && this.identity) {
                    const imageData =
                      await this.filesService.getProfilePictureOneTime(
                        this.identity as IIdentity
                      );
                    if (imageData) {
                      this.imageUrl = imageData;
                      this.isLoading = false;
                      this.snackBar.success(SUCCESS_TYPES.PROFILE_PIC_ADDED);
                    } else {
                      this.snackBar.error(
                        this.translate.instant(
                          'pages.profile.profile-picture-is-not-found'
                        )
                      );
                    }
                  } else {
                    this.snackBar.error(
                      this.translate.instant('pages.profile.error-404')
                    );
                  }
                },
                error: () => {
                  this.isLoading = false;
                  this.snackBar.error(
                    this.translate.instant('pages.profile.error-404')
                  );
                },
              });
          },
          error: () => {
            this.isLoading = false;
          },
        });
      }
    });
  }
}
